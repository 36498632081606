import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  /* Storage functions */
  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  store(key: string, data: any) {
    try {
      return localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      throw error;
    }
  }

  clear(): void {
    localStorage.clear();
  }
}
