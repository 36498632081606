// https://github.com/btsiders/ionic-print-test/issues/7
// https://stackoverflow.com/questions/65493049/media-print-page-break-before-page-break-after-property-not-working-in-ioni

// Esse service é chamado no app.component.ts

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(@Inject(DOCUMENT) private document: HTMLDocument) {}

  init() {
    window.onbeforeprint = () => this.setPrintStyles(true);
    window.onafterprint = () => this.setPrintStyles(false);
  }

  private setPrintStyles(add: boolean) {
    this.document.querySelectorAll('ion-content').forEach((element) => {
      const scroll = element.shadowRoot.querySelector('.inner-scroll') as HTMLElement;
      if (add) {
        scroll.style.position = 'relative';
      } else {
        scroll.style.removeProperty('position');
      }
    });
  }
}