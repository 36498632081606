import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SessionService } from './services/session.service';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'erp',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [SessionService]
  },
  {
    path: 'agreement/:uuid',
    loadChildren: () => import('./pages/agreement/agreement.module').then( m => m.AgreementPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule),
    canActivate: [SessionService]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    NgxGoogleAnalyticsModule.forRoot('G-QXQ9EC47RJ'),
    NgxGoogleAnalyticsRouterModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
