import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArboService {

  constructor(
    private httpService: HttpService
  ) { }

  create(type,data) {
    return this.httpService.post(environment.arboUrl+'/'+type,data);
  }

  get(type,id){
    return this.httpService.get(environment.arboUrl+'/'+type+'/'+id);
  }

  list(type,page=1,qtd=100,sectionId=null){
    const query = `?offset=${(page-1)*qtd}&limit=${qtd}`;
    if(sectionId)
      return this.httpService.get(environment.arboUrl+'/'+type+'/Section/'+sectionId+query);
    else
      return this.httpService.get(environment.arboUrl+'/'+type+query);
  }

  update(type,data){
    return this.httpService.put(environment.arboUrl+'/'+type,data);
  }

  delete(type,id){
    return this.httpService.delete(environment.arboUrl+'/'+type+'/'+id);
  }

  auth(login,password){
    return this.httpService.post(environment.arboUrl+'/User/auth',{login:login,password:password});
  }

  changePassword(data){
    return this.httpService.post(environment.arboUrl+'/User/changePassword',data);
  }

  // nice date helper functions
  timestampToDatetime(t){
    return new Date(t).toISOString().slice(0,19).replace('T',' ')
  }
  timestampToLocalDatetime(t){
    let tzoffset = new Date().getTimezoneOffset()*60000;
    return this.timestampToDatetime(t - tzoffset);
  }
  now(sec=0){
    return this.timestampToLocalDatetime(Date.now()+sec*1000);
  }

}
