import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RssFeedService } from './rss-feed.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    RssFeedService
  ]
})
export class ServicesModule { }
