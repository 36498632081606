import { HttpClient } from '@angular/common/http';
import * as xml2js from 'xml2js';
import { environment } from 'src/environments/environment';
import { NewsRss, IRssItem } from '../models/news-feed';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { ArboService } from './arbo.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RssFeedService {
  private rssFeedUrls: string[];

  constructor(
    private httpService: HttpService
    ) {
    this.rssFeedUrls = environment.newsFeedSources;
  }

  getAllRssFeedUrls(): string[] {
    return this.rssFeedUrls;
  }

  getRandomRssFeedUrl(): string {
    if (
      this.rssFeedUrls === null ||
      this.rssFeedUrls === undefined ||
      this.rssFeedUrls.length === 0
    ) {
      return null;
    }

    const randomSource = this.rssFeedUrls[this.getRandomInt(0, this.rssFeedUrls.length)];

    return `${environment.arboUrl}/RssProxy/${randomSource}`;

  }

  getRssFeedUrl(newsFeedSources): string {
    if (
      this.rssFeedUrls === null ||
      this.rssFeedUrls === undefined ||
      this.rssFeedUrls.length === 0
    ) {
      return null;
    }    

    return `${environment.arboUrl}/RssProxy/${this.rssFeedUrls[newsFeedSources]}`;

  }

  async getRssNewsFeed(sourceUrl: string): Promise<string> {

    const xmlData = await this.httpService.getAs(sourceUrl, {}, 'text/xml', 'xml');

    // console.log('xmlData', xmlData);

    return xmlData;

  }

  async getRssFeedItems(xml): Promise<IRssItem[]> {
    const newsRss: NewsRss = await xml2js.parseStringPromise(xml);

    // xml2js.parseString(xml, (err, result: NewsRss) => {
    //   this.RssData = result;
    // });

    const itemsArray = new Array<IRssItem>();

    //get list of feed items
    newsRss.rss.channel.forEach((chn: any) => {
      const feedItemList = chn.item;

      if (feedItemList.length) {
        feedItemList.forEach((feedItem: any) => {
          itemsArray.push(feedItem);
        });
      }
    });

    return itemsArray;
  }

  private getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }
}
